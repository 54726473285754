@import "~@/styles/variables/variables.scss";






















































@import '../runLayout.scss';
::v-deep {
	.jv-container {
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		max-height: 250px;
		overflow: auto;
	}
	.jv-container .jv-code {
		padding: 0;
	}
	.jv-code,
	.jv-code.open {
		padding-bottom: 0;
		padding: 0 15px;
	}
	.jv-node.jv-key-node {
		line-height: 21px;
	}
}
